import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { selectProfil } from '../+profil/profil.selectors';

import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

@Component({
  selector: 'app-users-list-group',
  templateUrl: './users-list-group.component.html',
  styleUrls: ['./users-list-group.component.css'],
})
export class UsersListGroupComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  constructor(
    private store: Store,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Benutzerverwaltung' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.profil$.subscribe((profil) => {
      if (profil._id) {
        this.store.dispatch(UserActions.loadUsers());
      }
    });
  }
}
