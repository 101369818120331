<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <app-users-list-grid
      [users$]="users$"
      [loading$]="loading$"
      [error$]="error$"
      [hideFirstname]="true"
      [hideLastname]="true"
    ></app-users-list-grid>
  </div>
</div>
