import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { Store } from '@ngrx/store';
import { Contact } from 'src/app/models/Contact';
import { Role } from 'src/app/models/Role';
import { ContactStoreService } from 'src/app/services/contact-store.service';
import { selectProfil, selectProfilRoles } from '../+profil/profil.selectors';
import {
  selectUsersError,
  selectInviteSuccess,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

@Component({
  selector: 'app-users-form-item-invite',
  templateUrl: './users-form-item-invite.component.html',
  styleUrls: ['./users-form-item-invite.component.css'],
})
export class UsersFormItemInviteComponent implements OnInit {
  @Output() invited = new EventEmitter<boolean>();

  profil$ = this.store.select(selectProfil);

  formBuilder = inject(FormBuilder);

  formInvite = this.formBuilder.group({
    Selected: new FormControl({} as Contact, { nonNullable: false }),

    Username: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$'),
      ],
    }),

    Firstname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    Lastname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    Roles: new FormControl([] as Role[], {
      nonNullable: true,
    }),
  });

  get username() {
    return this.formInvite.get('Username');
  }
  get firstname() {
    return this.formInvite.get('Firstname');
  }
  get lastname() {
    return this.formInvite.get('Lastname');
  }

  roles = [] as Role[];
  contacts = [] as Contact[];

  loadingRoles = false;
  loadingContacts = false;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['manager']));

  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  error$ = this.store.select(selectUsersError);
  invited$ = this.store.select(selectInviteSuccess);

  constructor(
    private store: Store,
    private contactStoreService: ContactStoreService,
  ) {}

  ngOnInit(): void {
    this.loadingRoles = true;
    this.loadingContacts = true;
    this.formInvite.get('Roles')?.disable();
    this.formInvite.get('Selected')?.disable();

    this.contactStoreService.getAll().subscribe((contacts) => {
      this.contacts = contacts
        .filter((contact) => contact.Factories.length > 0)
        .sort((a, b) => {
          if (a.Lastname && b.Lastname) {
            return a.Lastname.localeCompare(b.Lastname);
          }
          return 0;
        });
      this.loadingContacts = false;
      this.formInvite.get('Selected')?.enable();
    });

    this.profil$.subscribe((profil) => {
      if (profil) {
        this.roles = [];

        profil?.Roles?.map((r) => {
          this.roles.push(r);
        });

        this.roles = this.roles.sort((a, b) => a.Level - b.Level);

        this.loadingRoles = false;
        this.formInvite.get('Roles')?.enable();

        const selected = {
          Uid: profil.Uid,
        } as Contact;

        this.formInvite.get('Selected')?.setValue(selected);
      }
    });
  }

  onInviteCancel() {
    this.invited.emit(false);
  }

  onInviteSubmit() {
    this.validateBtnState = ClrLoadingState.LOADING;

    const data = this.formInvite.getRawValue();

    const send = {
      Uid: data.Selected?.Uid || '000000000',
      Xid: data.Selected?.Uid || '000000000',
      Username: data.Username,
      Firstname: data.Firstname,
      Lastname: data.Lastname,
      Roles: data.Roles.map((role) => role.Symbol),
    };

    this.store.dispatch(
      UserActions.inviteUser({
        uid: send.Uid,
        xid: send.Xid,
        username: send.Username,
        firstname: send.Firstname,
        lastname: send.Lastname,
        roles: send.Roles,
      }),
    );

    this.invited$.subscribe((invited) => {
      this.validateBtnState = ClrLoadingState.SUCCESS;
      this.invited.emit(true);
    });

    this.error$.subscribe((error) => {
      this.validateBtnState = ClrLoadingState.ERROR;
      this.invited.emit(false);
    });
  }
}
