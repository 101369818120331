import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

@Component({
  selector: 'app-users-list-users',
  templateUrl: './users-list-users.component.html',
  styleUrls: ['./users-list-users.component.css'],
})
export class UsersListUsersComponent implements OnInit {
  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  constructor(
    private store: Store,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}
  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Benutzerverwaltung' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.store.dispatch(UserActions.loadUsersAll());
  }
}
