<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h6 class="card-header">
        <cds-icon shape="user" size="md"></cds-icon>
        Benutzer
        <span style="float: right">
          <cds-icon shape="cog" size="md"></cds-icon>
        </span>
      </h6>
      <div class="card-block">
        <div class="card-title" *ngIf="(error$ | async)?.status">
          <div
            *ngIf="error$ | async as error"
            class="alert alert-danger"
            role="alert"
          >
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <cds-icon
                    class="alert-icon"
                    shape="exclamation-circle"
                  ></cds-icon>
                </div>
                <span class="alert-text">
                  {{ error.status }} | {{ error.statusText }}
                  <ng-container *ngIf="error.error.name">
                    | {{ error.error.name }} | {{ error.error.message }}
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-text">
          <ng-container *ngIf="profil$ | async as profil">
            <div cds-text="subsection">Info</div>
            <form
              clrForm
              [formGroup]="formProfil"
              (ngSubmit)="onProfilUpdateSubmit()"
            >
              <!-- Firstname -->
              <clr-input-container>
                <label
                  for="Firstname"
                  class="clr-col-12 clr-col-md-2 clr-required-mark"
                  >Vorname</label
                >
                <input
                  formControlName="Firstname"
                  clrInput
                  class="clr-col-12 clr-col-md-10"
                  style="width: 90%"
                  type="text"
                  id="Firstname"
                />
                <clr-control-error
                  *ngIf="
                    formProfil.get('Firstname')?.invalid &&
                    formProfil.get('Firstname')?.touched
                  "
                  >Firstname is required!</clr-control-error
                >
              </clr-input-container>

              <!-- Lastname -->
              <clr-input-container>
                <label
                  for="Lastname"
                  class="clr-col-12 clr-col-md-2 clr-required-mark"
                  >Nachname</label
                >
                <input
                  formControlName="Lastname"
                  clrInput
                  class="clr-col-12 clr-col-md-10"
                  style="width: 90%"
                  type="text"
                  id="Lastname"
                />
                <clr-control-error
                  *ngIf="
                    formProfil.get('Lastname')?.invalid &&
                    formProfil.get('Lastname')?.touched
                  "
                  >Lastname is required!</clr-control-error
                >
              </clr-input-container>

              <!-- Buttons -->
              <clr-control-container>
                <button
                  type="submit"
                  class="btn btn-primary"
                  [clrLoading]="(loading$ | async) === true"
                  [disabled]="formProfil.invalid"
                >
                  <cds-icon shape="sync"></cds-icon> aktualisieren
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-outline"
                  (click)="onProfilUpdateReset()"
                >
                  <cds-icon shape="times-circle"></cds-icon> reset
                </button>
              </clr-control-container>
            </form>

            <p></p>

            <div cds-text="subsection">Anmeldung</div>
            <form clrForm [formGroup]="formProfil">
              <!-- Username -->
              <clr-input-container>
                <label
                  for="Username"
                  class="clr-col-12 clr-col-md-2 clr-required-mark"
                  >Username</label
                >
                <input
                  clrInput
                  formControlName="Username"
                  class="clr-col-12 clr-col-md-10"
                  style="width: 90%"
                  type="text"
                  id="Username"
                />
                <clr-control-error
                  *ngIf="
                    formProfil.get('Username')?.invalid &&
                    formProfil.get('Username')?.touched
                  "
                  >Username is required!</clr-control-error
                >
              </clr-input-container>

              <!-- Password -->
              <clr-input-container>
                <label
                  for="empty"
                  class="clr-col-12 clr-col-md-2 clr-required-mark"
                  >Password</label
                >
                <input
                  clrInput
                  formControlName="Password"
                  class="clr-col-12 clr-col-md-10"
                  style="width: 90%"
                  type="password"
                  id="empty"
                />
                <clr-control-helper>
                  <cds-icon shape="child-arrow" size="xs"></cds-icon>&nbsp;<a
                    [routerLink]="['/request']"
                  >
                    Neues Passwort vergeben</a
                  >
                </clr-control-helper>
                <clr-control-error
                  *ngIf="
                    formProfil.get('Password')?.invalid &&
                    formProfil.get('Password')?.touched
                  "
                  >Password is required!</clr-control-error
                >
              </clr-input-container>
            </form>

            <p></p>

            <div cds-text="subsection">Sicherheit</div>
            <form clrForm [formGroup]="formProfil">
              <!-- Benachrichtigung -->
              <clr-toggle-container>
                <label for="Notification">Benachrichtigung</label>
                <clr-toggle-wrapper>
                  <input
                    type="checkbox"
                    clrToggle
                    formControlName="Notification"
                    id="Notification"
                    (click)="onNotificationClick($event)"
                  />
                  <label for="Notification"
                    >E-Mail nach einem <i>login</i></label
                  >
                </clr-toggle-wrapper>
                <clr-control-helper>E-Mail nach einem login</clr-control-helper>
              </clr-toggle-container>

              <!-- Authentication -->
              <clr-toggle-container>
                <label for="Authentication">Authentifizierung</label>
                <clr-toggle-wrapper>
                  <input
                    type="checkbox"
                    clrToggle
                    formControlName="Authentication"
                    id="Authentication"
                  />
                  <label for="Authentication"
                    >Sicherheitscode per <i>E-Mail</i></label
                  >
                </clr-toggle-wrapper>
                <clr-control-helper *ngIf="!profil.MAIL?.IsEnabled">
                  <button
                    type="button"
                    (click)="requestMfaMailEnable()"
                    class="btn btn-link btn-sm"
                  >
                    Sicherheitscode aktivieren
                  </button>
                </clr-control-helper>
                <clr-control-helper *ngIf="profil.MAIL?.IsEnabled">
                  <button
                    type="button"
                    (click)="requestMfaMailDisable()"
                    class="btn btn-link btn-sm"
                  >
                    Sicherheitscode deaktivieren
                  </button>
                </clr-control-helper>
              </clr-toggle-container>
            </form>

            <p></p>

            <!-- Kennung -->
            <ng-container *ngIf="(isService$ | async) === true">
              <div cds-text="subsection">Kennung</div>
              <form clrForm [formGroup]="formProfil">
                <clr-input-container>
                  <label for="Contact" class="clr-col-12 clr-col-md-2">
                    UID
                    <button class="btn btn-link" (click)="detail = true">
                      <cds-icon shape="info-circle"></cds-icon>
                    </button>
                  </label>

                  <input
                    *ngIf="
                      formContact.get('Lastname')?.value ||
                      formContact.get('Firstname')?.value
                    "
                    clrInput
                    disabled
                    class="clr-col-12 clr-col-md-10"
                    style="width: 90%"
                    type="text"
                    id="Contact"
                    value="{{ formContact.get('Lastname')?.value }},{{
                      formContact.get('Firstname')?.value
                    }} - Firmen: {{
                      formContact.get('Selected')?.value?.Factories?.length
                    }}"
                  />

                  <input
                    *ngIf="
                      !formContact.get('Lastname')?.value &&
                      !formContact.get('Firstname')?.value
                    "
                    clrInput
                    disabled
                    class="clr-col-12 clr-col-md-10"
                    style="width: 90%"
                    type="text"
                    id="Contact"
                    value=""
                  />

                  <clr-control-helper>
                    <button class="btn btn-primary" (click)="onNeueKennung()">
                      <cds-icon shape="switch"></cds-icon> ändern
                    </button>
                    &nbsp;
                    <button class="btn btn-outline" (click)="onDeleteKennung()">
                      <cds-icon shape="times-circle"></cds-icon> entfernen
                    </button>
                  </clr-control-helper>
                </clr-input-container>
              </form>
            </ng-container>
            <p></p>

            <!-- Rollen -->
            <ng-container *ngIf="profil.Roles?.length">
              <div cds-text="subsection">Rollen</div>
              <table class="table">
                <thead>
                  <tr>
                    <th class="left">Rolle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of profil.Roles">
                    <td class="left">{{ role.Name }}</td>
                  </tr>
                </tbody>
              </table>
              <p></p>
            </ng-container>

            <div cds-text="subsection">Anmeldung</div>
            <table class="table table-vertical" *ngIf="profil._id">
              <tbody>
                <!-- <tr>
                  <th style="width: 20%">Blocked</th>
                  <td>
                    <cds-icon
                      *ngIf="profil.SignIn?.IsBlocked"
                      shape="lock"
                    ></cds-icon>
                    {{
                      profil.SignIn.Blocked | date: "EEEE, dd.MM.YYYY HH:mm:ss"
                    }}
                    <cds-icon
                      *ngIf="!profil.SignIn?.IsBlocked"
                      shape="unlock"
                    ></cds-icon>
                  </td>
                </tr> -->
                <tr>
                  <th>Angemeldet</th>
                  <td>
                    {{
                      profil.SignIn.SignedAt | date: "EEEE, dd.MM.YYYY HH:mm:ss"
                    }}
                    <cds-icon
                      *ngIf="!profil.SignIn?.Signed"
                      shape="login"
                    ></cds-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <p></p>

            <!-- <div cds-text="subsection">Registrierung</div> -->
            <!-- <table class="table table-vertical" *ngIf="profil._id">
              <tbody>
                <tr>
                  <th style="width: 20%">Einladung</th>
                  <td>
                    <cds-icon
                      *ngIf="profil.SignUp?.IsInvited"
                      shape="check"
                    ></cds-icon>
                    {{
                      profil.SignUp.Invited | date: "EEEE, dd.MM.YYYY HH:mm:ss"
                    }}
                    <cds-icon
                      *ngIf="!profil.SignUp?.IsInvited"
                      shape="times"
                    ></cds-icon>
                  </td>
                </tr>
                <tr>
                  <th>Signierung</th>
                  <td>
                    <cds-icon
                      *ngIf="profil.SignUp?.IsSigned"
                      shape="check"
                    ></cds-icon>
                    {{
                      profil.SignUp.Signed | date: "EEEE, dd.MM.YYYY HH:mm:ss"
                    }}
                    <cds-icon
                      *ngIf="!profil.SignUp?.IsSigned"
                      shape="times"
                    ></cds-icon>
                  </td>
                </tr>
                <tr>
                  <th>Verifizierung</th>
                  <td>
                    <cds-icon
                      *ngIf="profil.SignUp?.IsVerified"
                      shape="check"
                    ></cds-icon>
                    {{
                      profil.SignUp.Verified | date: "EEEE, dd.MM.YYYY HH:mm:ss"
                    }}
                    <cds-icon
                      *ngIf="!profil.SignUp?.IsVerified"
                      shape="times"
                    ></cds-icon>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <!-- <p></p> -->
          </ng-container>
        </div>
      </div>
      <!-- <div class="card-footer">footer</div> -->
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="kennung">
  <h3 class="modal-title">
    <cds-icon shape="tag" size="md"></cds-icon>
    Kennung
  </h3>
  <div class="modal-body">
    <form clrForm [formGroup]="formContact">
      <clr-combobox-container>
        <label class="clr-col-12 clr-col-md-4" for="singleSelect">
          Kennung
          <span class="spinner spinner-inline" *ngIf="loading"></span>
        </label>
        <clr-combobox
          formControlName="Selected"
          class="clr-col-12 clr-col-md-8"
          style="width: 100%"
          id="singleSelect"
          (clrSelectionChange)="clrSelectionChange($event)"
        >
          <clr-options>
            <clr-option
              *clrOptionItems="let contact of contacts; field: 'Lastname'"
              [clrValue]="contact"
            >
              {{ contact.Lastname }}, {{ contact.Firstname }}
              <span class="badge">{{ contact.Factories.length }}</span>
              <!-- <span class="label">Firmen: {{ contact.Stations.length }}</span> -->
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="onNeueKennungCancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onNeueKennungOk()"
      [clrLoading]="submitBtnState"
    >
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="detail">
  <h3 class="modal-title">
    <cds-icon shape="user" size="md"></cds-icon>
    Kennung
  </h3>
  <div class="modal-body">
    <form clrForm [formGroup]="formContact">
      <clr-input-container>
        <label for="Uid" class="clr-col-12 clr-col-md-2">UID</label>
        <input
          clrInput
          formControlName="Uid"
          class="clr-col-12 clr-col-md-10"
          type="text"
          id="Uid"
          readonly
        />
      </clr-input-container>
      <clr-input-container>
        <label for="Firstname" class="clr-col-12 clr-col-md-2">Firstname</label>
        <input
          clrInput
          formControlName="Firstname"
          class="clr-col-12 clr-col-md-10"
          type="text"
          id="Firstname"
          readonly
        />
      </clr-input-container>
      <clr-input-container>
        <label for="Lastname" class="clr-col-12 clr-col-md-2">Lastname</label>
        <input
          clrInput
          formControlName="Lastname"
          class="clr-col-12 clr-col-md-10"
          type="text"
          id="Lastname"
          readonly
        />
      </clr-input-container>

      <clr-input-container *ngIf="formContact.get('Selected')?.value">
        <label for="Contracts" class="clr-col-12 clr-col-md-2">Firmen</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-10"
          type="text"
          [value]="formContact.get('Selected')?.value?.Factories?.length"
          readonly
        />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="detail = false">
      ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="mfaMailEnable">
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Zwei-Faktor-Authentifizierung
      </h3>
    </section>
  </h3>
  <div class="modal-body">
    <form class="login">
      Bitteg geben Sie hier den 6-stelligen Sicherheitscode ein, den wir soeben
      an Ihre E-Mail Adresse gesendet haben:
      <span cds-text="code">{{ Username?.value }}</span
      >.
      <p></p>
      <code-input
        #codeInput
        [isCodeHidden]="false"
        [codeLength]="6"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </code-input>
      <p></p>
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="confirmMfaMailEnable()"
        [clrLoading]="activateMfaMailBtnState"
      >
        aktivieren
      </button>

      <clr-alert
        *ngIf="isErrorMfaMail"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            Ungültige Anmeldeinformationen.
            <br />
            <code
              >{{ errorMfaMail.status }} |
              {{ errorMfaMail.statusText }}
              <ng-container *ngIf="errorMfaMail.message">
                | {{ errorMfaMail.error.message }}
              </ng-container></code
            >
          </span>
        </clr-alert-item>
      </clr-alert>
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline btn-block"
      (click)="onRequestMfaMailEnable()"
      [clrLoading]="requestMfaMailBtnState"
    >
      Sicherheitscode erneut senden
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="mfaMailDisable">
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Zwei-Faktor-Authentifizierung
      </h3>
    </section>
  </h3>
  <div class="modal-body">
    <form class="login">
      Bitteg geben Sie hier den 6-stelligen Sicherheitscode ein, den wir soeben
      an Ihre E-Mail Adresse gesendet haben:
      <span cds-text="code">{{ Username?.value }}</span
      >.
      <p></p>
      <code-input
        #codeInput
        [isCodeHidden]="false"
        [codeLength]="6"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </code-input>
      <p></p>
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="confirmMfaMailDisable()"
        [clrLoading]="activateMfaMailBtnState"
      >
        deaktivieren
      </button>

      <clr-alert
        *ngIf="isErrorMfaMail"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            Ungültige Anmeldeinformationen.
            <br />
            <code
              >{{ errorMfaMail.status }} |
              {{ errorMfaMail.statusText }}
              <ng-container *ngIf="errorMfaMail.message">
                | {{ errorMfaMail.error.message }}
              </ng-container></code
            >
          </span>
        </clr-alert-item>
      </clr-alert>
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline btn-block"
      (click)="onRequestMfaMailDisable()"
      [clrLoading]="requestMfaMailBtnState"
    >
      Sicherheitscode erneut senden
    </button>
  </div>
</clr-modal>
