import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';
import { UsersRoutingModule } from './users-routing.module';
import { UsersProfileComponent } from './users-profile/users-profile.component';
import { UsersListGroupComponent } from './users-list-group/users-list-group.component';
import { UsersListGridComponent } from './users-list-grid/users-list-grid.component';
import { UsersListRolesComponent } from './users-list-roles/users-list-roles.component';
import { CodeInputModule } from 'angular-code-input';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromUser from './+store/user.reducer';
import { UserEffects } from './+store/user.effects';

import * as fromProfil from './+profil/profil.reducer';
import { ProfilEffects } from './+profil/profil.effects';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  usersIcon,
  userIcon,
  childArrowIcon,
  eraserIcon,
  dotCircleIcon,
  lockIcon,
  unlockIcon,
  qrCodeIcon,
  checkIcon,
  timesIcon,
  calendarIcon,
  infoCircleIcon,
  successStandardIcon,
  exclamationCircleIcon,
  timesCircleIcon,
  switchIcon,
  flagIcon,
  syncIcon,
  factoryIcon,
} from '@cds/core/icon';
import { UsersListUsersComponent } from './users-list-users/users-list-users.component';
import { UsersFormItemEditComponent } from './users-form-item-edit/users-form-item-edit.component';
import { UsersFormItemInviteComponent } from './users-form-item-invite/users-form-item-invite.component';
import { UsersFormItemDeleteComponent } from './users-form-item-delete/users-form-item-delete.component';
import { UsersRightsFactoriesComponent } from './users-rights-factories/users-rights-factories.component';
import { UsersRightsFactoriesFormComponent } from './users-rights-factories-form/users-rights-factories-form.component';

ClarityIcons.addIcons(
  usersIcon,
  userIcon,
  childArrowIcon,
  eraserIcon,
  dotCircleIcon,
  lockIcon,
  unlockIcon,
  qrCodeIcon,
  checkIcon,
  timesIcon,
  calendarIcon,
  infoCircleIcon,
  successStandardIcon,
  exclamationCircleIcon,
  timesCircleIcon,
  switchIcon,
  flagIcon,
  syncIcon,
  factoryIcon,
);

@NgModule({
  declarations: [
    UsersProfileComponent,
    UsersListGridComponent,
    UsersListRolesComponent,
    UsersListGroupComponent,
    UsersListUsersComponent,
    UsersFormItemEditComponent,
    UsersFormItemInviteComponent,
    UsersFormItemDeleteComponent,
    UsersRightsFactoriesComponent,
    UsersRightsFactoriesFormComponent,
  ],
  imports: [
    UsersRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    ClarityModule,
    ClrAddonsModule,
    // StoreModule.forFeature(fromContact.contactFeatureKey, fromContact.reducer),
    // EffectsModule.forFeature([ContactEffects]),
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature(fromProfil.profilFeatureKey, fromProfil.reducer),
    EffectsModule.forFeature([ProfilEffects]),
    CodeInputModule,
  ],
})
export class UsersModule {}
